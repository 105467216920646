<template>
    <b-card>
        <b-row>
            <b-col>
                <line-chart chart-id="line-daily" v-if="scores" :chart-data="scores" :chart-labels="labels" />
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import LineChart from './charts/LineChart.vue';

export default {
    components: { LineChart },
    name: 'ScorecardChart',
    props: {
        chartData: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            scores: null,
            labels: []
        };
    },
    watch: {
        chartData: function(value) {
            var data = value.map(entry => entry.scores);
            if (data.length) {
                this.scores = [
                    {
                        label: 'Score',
                        data: data
                    }
                ];
            }
            this.labels = value.map(entry => entry.day);
        }
    }
};
</script>
