<template>
    <div>
        <h4>{{ title }}</h4>
        <b-row class="">
            <b-col class="col-auto">
                <b-avatar class="badge-bg" :icon="iconValue" size="5rem"></b-avatar>
            </b-col>
            <b-col class="col-auto my-auto score">{{ score | number(1) }}</b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'ScorecardBadge',
    props: {
        score: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            default: 'Last Score'
        }
    },
    computed: {
        iconValue() {
            if (!this.score) {
                return 'dice4-fill';
            }
            const num = this.$options.filters.number(this.score, 1).split('.')[0];
            return `dice${num}-fill`;
        }
    }
};
</script>
<style lang="scss" scoped>
.score {
    font-size: 2em;
    font-weight: bold;
}

.badge-bg {
    background-color: $med-corp;
}
</style>
