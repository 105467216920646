<template>
    <b-card v-if="user">
        <b-row>
            <b-col>
                <h4>Feedback</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    striped
                    hover
                    :items="scorecards"
                    :busy="isLoading"
                    :fields="fields"
                    thead-class="hidden_header"
                >
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(feedback)="data">
                        <p class="line-clamp" v-b-tooltip.hover :title="data.item.feedback">{{ data.item.feedback }}</p>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import httpClient from '../services';
export default {
    name: 'ScorecardFeedback',
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            scorecards: [],
            fields: [
                {
                    key: 'feedback',
                    sortable: false
                }
            ]
        };
    },
    mounted() {
        if (this.user && this.user.id) {
            this.getScorecards();
        }
    },
    methods: {
        getScorecards() {
            this.isLoading = true;
            httpClient
                .get(`/scorecards/user/${this.user.id}/feedback/?orderBy=desc&sortBy=dateCompleted`)
                .then(response => {
                    this.scorecards = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
    watch: {
        user: function(value) {
            if (value.id) {
                this.getScorecards();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
<style>
.hidden_header {
    display: none;
}
</style>
