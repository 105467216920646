<template>
    <div>
        <template v-if="question.questionType === 'To5'">
            <b-form-select v-model="value.answer" :options="to5Options"></b-form-select>
        </template>
        <template v-else>
            <b-form-checkbox v-model="value.answer" switch size="lg">
                <span v-if="value.answer && (value.answer === 'true' || value.answer === true)">Yes</span>
                <span v-else>No</span>
            </b-form-checkbox>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ScorecardAnswerInput',
    props: {
        question: {
            type: Object,
            required: true
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            to5Options: [
                { value: 0, text: 0 },
                { value: 1, text: 1 },
                { value: 2, text: 2 },
                { value: 3, text: 3 },
                { value: 4, text: 4 },
                { value: 5, text: 5 }
            ]
        };
    },
    mounted() {
        if (typeof this.value.answer === 'undefined' || this.value.answer === null) {
            if (this.question.questionType === 'To5') {
                this.value.answer = 0;
            } else {
                this.value.answer = false;
            }
        }
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    }
};
</script>
