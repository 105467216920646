<script>
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Classic20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import pluginTrendlineLinear from 'chartjs-plugin-trendline';
import { Line } from 'vue-chartjs';

export default {
    extends: Line,
    props: {
        chartData: {
            required: false
        },
        chartLabels: {
            required: true
        },
        showTrend: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            gradient: null,
            options: {
                showScale: true,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 5,
                                callback: value => {
                                    return this.formatNumber(value);
                                }
                            },
                            gridLines: {
                                display: true,
                                color: '#EEF0F4',
                                borderDash: [1, 2, 3, 4]
                            }
                        }
                    ],
                    xAxes: [
                        {
                            ticks: {
                                callback: value => {
                                    return this.formatDate(value);
                                }
                            },
                            gridLines: {
                                display: true,
                                color: '#EEF0F4',
                                borderDash: [5, 15]
                            }
                        }
                    ]
                },
                tooltips: {
                    backgroundColor: '#4F5565',
                    titleFontStyle: 'normal',
                    titleFontSize: 18,
                    bodyFontFamily: "'Proxima Nova', sans-serif",
                    cornerRadius: 3,
                    bodyFontColor: '#20C4C8',
                    bodyFontSize: 14,
                    xPadding: 14,
                    yPadding: 14,
                    displayColors: false,
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        title: tooltipItem => {
                            return this.$luxon(tooltipItem[0].xLabel, 'date_med');
                        },
                        label: (tooltipItem, data) => {
                            let dataset = data.datasets[tooltipItem.datasetIndex];
                            let currentValue = dataset.data[tooltipItem.index];
                            if (currentValue === null) {
                                return '';
                            }
                            return `${dataset.label} - ${currentValue.toFixed(1)}`;
                        }
                    }
                },
                legend: {
                    display: true,
                    labels: {
                        lineWidth: 1
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    colorschemes: {
                        scheme: Classic20
                    }
                }
            }
        };
    },
    computed: {
        datasets() {
            const dataArray = [];
            for (const data of this.chartData) {
                const dataset = {
                    label: data.label,
                    pointBackgroundColor: 'rgba(0,0,0,0)',
                    pointBorderColor: 'rgba(0,0,0,0)',
                    pointHoverRadius: 4,
                    pointHitRadius: 10,
                    pointHoverBorderWidth: 1,
                    borderWidth: 3,
                    fill: false,
                    data: data.data
                };
                if (this.showTrend) {
                    dataset.trendlineLinear = {
                        style: '#6c757d',
                        lineStyle: 'solid',
                        width: 2
                    };
                }
                dataArray.push(dataset);
            }
            return dataArray;
        }
    },
    mounted() {
        this.addPlugin(pluginTrendlineLinear);
        this.renderChart(
            {
                labels: this.chartLabels,
                datasets: this.datasets
            },
            this.options
        );
    },
    methods: {
        formatNumber(num) {
            return num.toFixed(1).toString();
        },
        formatDate(date) {
            return this.$luxon(date, 'date_med');
        }
    }
};
</script>
