<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item active>Dashboard</b-breadcrumb-item>
        </b-breadcrumb>
        <team-scorecard :user="user" :orgUser="orgUser" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@/services';
import TeamScorecard from '../../components/TeamScorecard';
export default {
    name: 'Home',
    components: { TeamScorecard },
    computed: {
        ...mapGetters(['currentUser'])
    },
    data() {
        return {
            userId: null,
            user: {},
            orgUser: {}
        };
    },
    mounted() {
        this.user = this.currentUser;
        this.getUser();
    },
    methods: {
        getUser() {
            httpClient
                .get(`/user/${this.currentUser.id}`)
                .then(response => {
                    this.orgUser = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
