<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Team Members</h1>
            </b-col>
        </b-row>
        <b-card v-if="team.length">
            <b-table striped hover :items="team" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'ScorecardComplete', params: { userId: data.item.user.id } }">
                        {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                    </router-link>
                </template>
                <template #cell(dateCompleted)="data">
                    <span v-if="data.item.latestScorecard">
                        {{ data.item.latestScorecard.dateCompleted | luxon('med') }}
                    </span>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@/services';
export default {
    name: 'MyTeam',
    computed: {
        ...mapGetters(['currentUser'])
    },
    data() {
        return {
            orgUser: {},
            team: [],
            fields: [
                {
                    key: 'name',
                    sortable: false
                },
                {
                    key: 'dateCompleted',
                    label: 'Last Completed',
                    sortable: false
                }
            ]
        };
    },
    mounted() {
        this.getUser();
        this.getTeam();
    },
    methods: {
        getTeam() {
            httpClient.get(`/user/team`).then(response => {
                console.info(response.data);
                this.team = response.data;
            });
        },
        getUser() {
            if (!this.currentUser) {
                return;
            }
            httpClient
                .get(`/user/${this.currentUser.id}`)
                .then(response => {
                    this.orgUser = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
