<template>
    <div v-if="user" class="container">
        <h1>Scorecard for {{ user.firstName }} {{ user.lastName }}</h1>
        <template v-if="!scorecard">
            <b-row>
                <b-col>
                    <h3>Which roles are you evaluating on?</h3>
                    <b-row>
                        <b-col>
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-checkbox
                                    v-for="option in roles"
                                    v-model="selectedRoles"
                                    :key="option.id"
                                    :value="option"
                                    :aria-describedby="ariaDescribedby"
                                    name="selectedRoles"
                                    button
                                    :button-variant="
                                        selectedRoles.some(x => x.id === option.id) ? 'secondary' : 'light'
                                    "
                                    class="m-1"
                                >
                                    {{ option.name }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4 mb-4">
                        <b-col>
                            <b-button
                                variant="info"
                                :disabled="processing || selectedRoles.length === 0"
                                @click="createScorecard"
                            >
                                {{ $t('buttons.next') }}
                                <b-spinner v-if="processing" small type="grow" />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <scorecard-form :scorecard="scorecard" />
            <b-row class="mt-4 mb-4">
                <b-col>
                    <b-button variant="secondary" :disabled="processing" @click="saveForLater">
                        {{ $t('buttons.saveForLater') }} <b-spinner v-if="processing" small type="grow" />
                    </b-button>
                </b-col>
                <b-col class="text-right">
                    <b-button variant="info" :disabled="processing" @click="save">
                        {{ $t('buttons.save') }}
                        <b-spinner v-if="processing" small type="grow" />
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@/services';
import ScorecardForm from '../../components/ScorecardForm.vue';
export default {
    components: { ScorecardForm },
    name: 'CompleteScorecard',
    computed: {
        ...mapGetters(['isOrgAdmin'])
    },
    data() {
        return {
            scorecard: null,
            scorecardId: null,
            processing: false,
            roles: null,
            selectedRoles: [],
            user: null,
            userId: null,
            userScorecard: {
                categories: {}
            }
        };
    },
    mounted() {
        const { userId } = this.$route.params;
        this.userId = userId;
        this.getScorecard();
        this.getUser();
    },
    methods: {
        getScorecard() {
            httpClient
                .get(`/scorecards/user/${this.userId}/current`)
                .then(response => {
                    this.scorecard = response.data;
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        // need to present roles to complete
                        console.info('Present roles');
                        this.getRoles();
                    } else {
                        console.error(error);
                    }
                });
        },
        getRoles() {
            httpClient
                .get('/roles')
                .then(response => {
                    this.roles = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getUser() {
            httpClient
                .get(`/user/${this.userId}`)
                .then(response => {
                    this.user = response.data.user;
                    this.selectedRoles = response.data.primaryRoles;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getCategorySaveObj() {
            const categories = {};
            for (const items of this.scorecard.scorecardCategories) {
                categories[items.category.id] = {};
                for (const question of items.questions) {
                    categories[items.category.id][question._id] = question.answer;
                }
            }
            return categories;
        },
        createScorecard() {
            // Create a draft scorecard for the user.
            httpClient
                .post(`/scorecards/user/${this.userId}`, { roles: this.selectedRoles })
                .then(() => {
                    this.getScorecard();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        save() {
            this.processing = true;
            const data = {
                scorecardId: this.scorecard.id,
                status: 'Final',
                feedback: this.scorecard.feedback,
                scorecardCategories: this.getCategorySaveObj()
            };
            if (this.isOrgAdmin) {
                data['dateCompleted'] = this.scorecard.dateCompleted;
            }
            httpClient
                .post(`/scorecards/save`, data)
                .then(() => {
                    this.$router.push({ name: 'Home' });
                })
                .finally(() => {
                    this.processing = false;
                });
        },
        saveForLater() {
            this.processing = true;
            const data = {
                scorecardId: this.scorecard.id,
                status: 'Draft',
                feedback: this.scorecard.feedback,
                scorecardCategories: this.getCategorySaveObj()
            };
            if (this.isOrgAdmin) {
                data['dateCompleted'] = this.scorecard.dateCompleted;
            }
            httpClient
                .post(`/scorecards/save`, data)
                .then(() => {
                    this.$router.push({ name: 'TeamScorecard' });
                })
                .finally(() => {
                    this.processing = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
h1 {
    margin: 40px 0;
}
</style>
