<template>
    <b-card>
        <b-row>
            <b-col>
                <h4>{{ title }}</h4>
            </b-col>
            <b-col class="text-right">
                <b-button-group size="sm">
                    <b-button :pressed="bucket === 'week'" @click="toggleBucket('week')">By Week</b-button>
                    <b-button :pressed="bucket === 'month'" @click="toggleBucket('month')">By Month</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table-simple v-if="breakdown.length" responsive>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th sticky-column>User</b-th>
                            <b-th v-if="showAverage">Average<br /><small>12 weeks</small></b-th>
                            <b-th v-for="(heading, index) in scoreFields" :key="index">
                                <span v-if="!isLoading && bucket === 'week'">
                                    Week of<br />
                                    <small>{{ heading.label }}</small>
                                </span>
                                <span v-else-if="!isLoading">
                                    {{ heading.label }}
                                </span>
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="data in breakdown" :key="data._id">
                            <b-td sticky-column>{{ data.firstName }} {{ data.lastName }}</b-td>
                            <b-td v-if="showAverage">
                                <span v-if="data.scores && data.scores.average !== undefined">
                                    {{ data.scores.average | number(1) }}
                                </span>
                                <span v-else>-</span>
                            </b-td>
                            <b-td v-for="(heading, hIndex) in scoreFields" :key="hIndex">
                                <span v-if="data.breakdown[heading.key] !== undefined">
                                    {{ data.breakdown[heading.key] | number(1) }}
                                </span>
                                <span v-else>-</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: 'ScoreUserBreakdown',
    props: {
        getData: {
            type: Function,
            required: true
        },
        showAverage: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'Team Rank'
        }
    },
    data() {
        return {
            isLoading: false,
            bucket: 'week',
            breakdown: [],
            scoreFields: []
        };
    },
    mounted() {
        this.getBreakdown();
    },
    methods: {
        getBreakdown() {
            this.isLoading = true;
            this.getData(this.bucket)
                .then(response => {
                    this.scoreFields = response.data.fields;
                    this.breakdown = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toggleBucket(bucket) {
            this.bucket = bucket;
            this.getBreakdown();
        }
    }
};
</script>
