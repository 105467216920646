<template>
    <b-card>
        <b-row>
            <b-col>
                <h2>Scorecard</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col class="col-auto"><b-avatar class="mr-3" size="6rem"></b-avatar></b-col>
                    <b-col class="my-auto">
                        <h1 class="mr-auto">{{ user.firstName }} {{ user.lastName }}</h1>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col>
                <h4>{{ $tc('profile.roleLabel', numRoles) }}</h4>
                <div v-for="(role, index) in orgUser.primaryRoles" :key="index">{{ role.name }}</div>
            </b-col>
            <b-col v-if="score && score.score !== undefined">
                <score-badge title="Personal Score" :score="score.score" />
            </b-col>
            <b-col v-if="score && score.teamScore !== undefined">
                <score-badge title="Team Score" :score="score.teamScore" />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import ScoreBadge from './ScoreBadge.vue';
export default {
    components: { ScoreBadge },
    name: 'Scorecard',
    props: {
        user: {
            type: Object,
            required: true
        },
        orgUser: {
            type: Object,
            required: true
        },
        score: {
            type: Object
        }
    },
    computed: {
        numRoles() {
            if (this.orgUser && Array.isArray(this.orgUser.primaryRoles)) {
                return this.orgUser.primaryRoles.length;
            }
            return 0;
        }
    }
};
</script>
