<template>
    <b-card>
        <b-row>
            <b-col>
                <h4>Teams</h4>
            </b-col>
            <b-col class="text-right">
                <b-button-group size="sm">
                    <b-button :pressed="bucket === 'week'" @click="toggleBucket('week')">By Week</b-button>
                    <b-button :pressed="bucket === 'month'" @click="toggleBucket('month')">By Month</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row class="table-heading p-2 ml-0 mr-0 align-items-end">
                    <b-col xs="3" class="font-weight-bold">User</b-col>
                    <b-col class="p-0 font-weight-bold">Average</b-col>
                    <b-col class="p-0 font-weight-bold">Team Average</b-col>
                    <b-col v-for="(heading, index) in shownColumns" :key="index" class="p-0">
                        <span v-if="!isLoading && bucket === 'week'">
                            <span class="font-weight-bold">Week of</span><br />
                            <small>{{ heading.label }}</small>
                        </span>
                        <span v-else-if="!isLoading" class="font-weight-bold">
                            {{ heading.label }}
                        </span>
                    </b-col>
                </b-row>
                <org-user-breakdown
                    v-for="row in breakdown"
                    :key="row.id"
                    :user="row"
                    :toggleTeam="toggleTeam"
                    :scoreFields="scoreFields"
                />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import httpClient from '@/services';
import OrgUserBreakdown from './OrgUserBreakdown.vue';
export default {
    components: { OrgUserBreakdown },
    name: 'ScorecardOrgBreakdown',
    data() {
        return {
            isLoading: false,
            bucket: 'week',
            breakdown: [],
            scoreFields: []
        };
    },
    computed: {
        shownColumns() {
            if (this.$screen.lt.sm) {
                return this.scoreFields.slice(0, 1);
            }
            return this.scoreFields;
        }
    },
    mounted() {
        this.getBreakdown();
    },
    methods: {
        getTeamBreakdown(bucket) {
            return httpClient.get(`/scorecards/organization?bucket=${bucket}`);
        },
        getBreakdown() {
            this.isLoading = true;
            this.getTeamBreakdown(this.bucket)
                .then(response => {
                    this.scoreFields = response.data.fields;
                    this.breakdown = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toggleTeam(user, expandState) {
            if (!expandState) {
                // Minimize team. Do nothing;
                return;
            }
            if (user.team) {
                return;
            }
            httpClient
                .get(`/scorecards/team/breakdownByUser?bucket=${this.bucket}&managerId=${user.id}`)
                .then(response => {
                    this.$set(user, 'team', response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        toggleBucket(bucket) {
            this.bucket = bucket;
            this.getBreakdown();
        }
    }
};
</script>
<style lang="scss" scoped>
.table-heading {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
</style>
