<template>
    <b-card v-if="team.length">
        <b-row>
            <b-col>
                <h4>My Team</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table responsive striped hover :items="team" :busy="isLoading" :fields="fields">
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(name)="data">
                        {{ data.item.user.firstName }} {{ data.item.user.lastName }}
                    </template>
                    <template #cell(role)="data">
                        <div v-for="role in data.item.primaryRoles" :key="role._id">
                            {{ role.name }}
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: 'MyTeam',
    props: {
        team: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            fields: [
                {
                    key: 'name'
                },
                {
                    key: 'role',
                    label: 'Primary Role',
                    sortable: false
                }
            ]
        };
    }
};
</script>
