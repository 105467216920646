<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item active>Dashboard</b-breadcrumb-item>
        </b-breadcrumb>
        <user-scorecard :user="user" :orgUser="orgUser" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@/services';
import UserScorecard from '../../components/UserScorecard.vue';
export default {
    components: { UserScorecard },
    name: 'Home',
    computed: {
        ...mapGetters(['currentUser'])
    },
    data() {
        return {
            userId: null,
            user: {},
            orgUser: {}
        };
    },
    mounted() {
        const { userId } = this.$route.params;
        this.userId = userId;
        this.getUser();
    },
    methods: {
        getUser() {
            httpClient
                .get(`/user/${this.userId}`)
                .then(response => {
                    this.user = response.data.user;
                    this.orgUser = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
