<template>
    <div v-if="user">
        <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'UserDashboard', params: { userId: userId } }">
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'ViewScorecard', params: { userId: userId, scorecardId: scorecardId } }">
                Scorecard
            </b-breadcrumb-item>
        </b-breadcrumb>
        <h1>Edit Scorecard for {{ user.firstName }} {{ user.lastName }}</h1>
        <template v-if="scorecard">
            <scorecard-form :scorecard="scorecard" />
            <b-row class="mt-4 mb-4">
                <b-col>
                    <b-button variant="info" :disabled="processing" @click="save">
                        {{ $t('buttons.save') }}
                        <b-spinner v-if="processing" small type="grow" />
                    </b-button>
                </b-col>
                <b-col>
                    <b-button variant="secondary" @click="cancel">
                        {{ $t('buttons.cancel') }}
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@/services';
import ScorecardForm from '../../components/ScorecardForm.vue';
export default {
    components: { ScorecardForm },
    name: 'CompleteScorecard',
    computed: {
        ...mapGetters(['isOrgAdmin'])
    },
    data() {
        return {
            scorecard: null,
            scorecardId: null,
            processing: false,
            user: null,
            userId: null
        };
    },
    mounted() {
        const { userId, scorecardId } = this.$route.params;
        this.userId = userId;
        this.scorecardId = scorecardId;
        this.getScorecard();
        this.getUser();
    },
    methods: {
        getScorecard() {
            httpClient
                .get(`/scorecards/${this.scorecardId}`)
                .then(response => {
                    this.scorecard = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getUser() {
            httpClient
                .get(`/user/${this.userId}`)
                .then(response => {
                    this.user = response.data.user;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getCategorySaveObj() {
            const categories = {};
            for (const items of this.scorecard.scorecardCategories) {
                categories[items.category.id] = {};
                for (const question of items.questions) {
                    categories[items.category.id][question._id] = question.answer;
                }
            }
            return categories;
        },
        save() {
            this.processing = true;
            const data = {
                scorecardId: this.scorecard.id,
                status: 'Final',
                feedback: this.scorecard.feedback,
                scorecardCategories: this.getCategorySaveObj()
            };
            if (this.isOrgAdmin) {
                data['dateCompleted'] = this.scorecard.dateCompleted;
            }
            httpClient
                .post(`/scorecards/save`, data)
                .then(() => {
                    this.$router.push({
                        name: 'ViewScorecard',
                        params: { userId: this.userId, scorecardId: this.scorecardId }
                    });
                })
                .finally(() => {
                    this.processing = false;
                });
        },
        cancel() {
            this.$router.push({
                name: 'ViewScorecard',
                params: { userId: this.userId, scorecardId: this.scorecardId }
            });
        }
    }
};
</script>
