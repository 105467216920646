<template>
    <div v-if="user">
        <h2>Team Scorecard</h2>
        <b-row>
            <b-col md="8" sm="12">
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-team-chart />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-user-breakdown :getData="getTeamBreakdown" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-breakdown :getData="getBreakdown" title="Team Role Breakdown" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="4" sm="12">
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-badge :score="currentScore" title="Team Average" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <my-team :team="team" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import httpClient from '@/services';
import ScorecardBreakdown from './ScorecardBreakdown.vue';
import MyTeam from './MyTeam.vue';
import ScorecardUserBreakdown from './ScorecardUserBreakdown.vue';
import ScorecardTeamChart from './ScorecardTeamChart.vue';
import ScorecardBadge from './ScorecardBadge.vue';
export default {
    components: { ScorecardBreakdown, MyTeam, ScorecardUserBreakdown, ScorecardTeamChart, ScorecardBadge },
    name: 'TeamScorecard',
    props: {
        user: {
            type: Object,
            required: true
        },
        orgUser: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            chartData: [],
            team: []
        };
    },
    mounted() {
        // get data
        if (this.user && this.user.id) {
            this.init();
        }
    },
    computed: {
        currentScore() {
            if (!this.orgUser.scores || this.orgUser.scores.teamScore === undefined) {
                return {};
            }
            return {
                score: this.orgUser.scores.teamScore
            };
        }
    },
    methods: {
        init() {
            this.getTeam();
        },
        getTeam() {
            httpClient
                .get(`/user/${this.user.id}/team`)
                .then(response => {
                    this.team = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getTeamBreakdown(bucket) {
            return httpClient.get(`/scorecards/team/breakdownByUser?bucket=${bucket}`);
        },
        getBreakdown(bucket) {
            return httpClient.get(`/scorecards/team/breakdownByRole?bucket=${bucket}`);
        }
    },
    watch: {
        user: function(value) {
            if (value.id) {
                this.init();
            }
        }
    }
};
</script>
