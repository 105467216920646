<template>
    <div>
        <template v-if="getTeam.length">
            <b-alert show variant="danger">
                Do not forget to complete the scorecard for your
                <router-link :to="{ name: 'TeamScorecard' }">
                    team.
                </router-link>
            </b-alert>
        </template>
        <user-scorecard :user="currentUser" :orgUser="orgUser" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@/services';
import UserScorecard from '../../components/UserScorecard.vue';
export default {
    components: { UserScorecard },
    name: 'MyProfile',
    computed: {
        ...mapGetters(['currentUser', 'getTeam'])
    },
    data() {
        return {
            orgUser: {}
        };
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser() {
            if (!this.currentUser) {
                return;
            }
            httpClient
                .get(`/user/${this.currentUser.id}`)
                .then(response => {
                    this.orgUser = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
