<template>
    <div>
        <b-row class="p-2 ml-0 mr-0">
            <b-col xs="3">
                <b-icon-plus-square v-if="user.hasTeam && !showTeam" @click="toggle(user)"></b-icon-plus-square>
                <b-icon-dash-square v-else-if="showTeam" @click="toggle(user)"></b-icon-dash-square>
                {{ user.firstName }} {{ user.lastName }}
            </b-col>
            <b-col class="p-0">
                <span v-if="user.scores && user.scores.average !== undefined">
                    {{ user.scores.average | number(1) }}
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col class="p-0">
                <span v-if="user.scores && user.scores.teamScore !== undefined">
                    {{ user.scores.teamScore | number(1) }}
                </span>
                <span v-else>-</span>
            </b-col>
            <b-col v-for="(heading, hIndex) in shownColumns" :key="hIndex" class="p-0">
                <span v-if="user.breakdown[heading.key] !== undefined">
                    {{ user.breakdown[heading.key] | number(1) }}
                </span>
                <span v-else>-</span>
            </b-col>
        </b-row>
        <b-row v-if="showTeam && !team.length">
            <b-col>
                <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </b-col>
        </b-row>

        <org-user-breakdown
            v-for="teamUser in team"
            :user="teamUser"
            :toggleTeam="toggleTeam"
            :scoreFields="scoreFields"
            :key="teamUser.id"
        />
    </div>
</template>

<script>
export default {
    name: 'OrgUserBreakdown',
    props: {
        user: {
            type: Object,
            required: true
        },
        toggleTeam: {
            type: Function,
            required: true
        },
        scoreFields: {
            type: Array
        }
    },
    data() {
        return {
            showTeam: false
        };
    },
    computed: {
        team() {
            if (!this.showTeam || !Array.isArray(this.user.team)) {
                return [];
            }
            return this.user.team;
        },
        shownColumns() {
            if (this.$screen.lt.sm) {
                return this.scoreFields.slice(0, 1);
            }
            return this.scoreFields;
        }
    },
    methods: {
        toggle(user) {
            this.showTeam = !this.showTeam;
            this.toggleTeam(user, this.showTeam);
        }
    }
};
</script>
