<template>
    <b-card>
        <b-row class="mb-4">
            <b-col class="text-center">
                <b-avatar class="badge-bg" :icon="iconValue" size="10rem"></b-avatar>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col v-if="score.score !== undefined" class="text-center">
                <b-row>
                    <b-col class="text-nowrap">
                        <h4>{{ title }}</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center score">{{ score.score | number(1) }}</b-col>
                </b-row>
            </b-col>
            <b-col v-if="score.teamScore !== undefined" class="text-center">
                <b-row>
                    <b-col>
                        <h4 class="text-nowrap">Team Score</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center score">{{ score.teamScore | number(1) }}</b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="score.dateCompleted">
            <b-col>
                <small>Updated: {{ score.dateCompleted | luxon('date_full') }}</small>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
export default {
    name: 'ScorecardBadge',
    props: {
        score: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: 'Last Score'
        }
    },
    computed: {
        iconValue() {
            if (!this.score.score) {
                return 'dice4-fill';
            }
            const num = this.$options.filters.number(this.score.score, 1).split('.')[0];
            return `dice${num}-fill`;
        }
    }
};
</script>
<style lang="scss" scoped>
.score {
    font-size: 4em;
    font-weight: bold;
}

.badge-bg {
    background-color: $med-corp;
}
</style>
