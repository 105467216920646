<template>
    <div v-if="user && user.id">
        <b-row>
            <b-col md="8" sm="12">
                <b-row>
                    <b-col class="mb-4">
                        <scorecard :user="user" :orgUser="orgUser" :score="currentScore" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-chart :chartData="chartData" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-breakdown :getData="getBreakdown" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="4" sm="12">
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-comments :user="user" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-list :user="user" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import httpClient from '@/services';
import ScorecardChart from '../components/ScorecardChart.vue';
import ScorecardBreakdown from './ScorecardBreakdown.vue';
import ScorecardComments from './ScorecardFeedback.vue';
import ScorecardList from './ScorecardList.vue';
import Scorecard from './Scorecard.vue';
export default {
    components: { ScorecardChart, ScorecardBreakdown, ScorecardComments, ScorecardList, Scorecard },
    name: 'UserScorecard',
    props: {
        user: {
            type: Object,
            required: true
        },
        orgUser: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            chartData: [],
            currentScore: {}
        };
    },
    mounted() {
        // get data
        if (this.user && this.user.id) {
            this.getScore(this.user.id);
        }
    },
    methods: {
        getScore(userId) {
            httpClient
                .get(`/scorecards/user/${userId}/scores`)
                .then(response => {
                    this.currentScore = response.data.currentScore;
                    this.chartData = response.data.chartData;
                })
                .catch(error => {
                    console.error(error);
                });
        },

        getBreakdown(bucket) {
            return httpClient.get(`/scorecards/user/${this.user.id}/breakdownByRole?bucket=${bucket}`);
        }
    },
    watch: {
        user: function(value) {
            if (value.id) {
                this.getScore(value.id);
            }
        }
    }
};
</script>
