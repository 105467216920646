<template>
    <b-card>
        <b-row>
            <b-col>
                <b-row>
                    <b-col>
                        <h4>Breakdown</h4>
                    </b-col>
                    <b-col class="text-right">
                        <b-button-group size="sm">
                            <b-button :pressed="bucket === 'week'" @click="toggleBucket('week')">By Week</b-button>
                            <b-button :pressed="bucket === 'month'" @click="toggleBucket('month')">By Month</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table
                            responsive
                            :fields="fields"
                            :items="breakdown"
                            :busy="isLoading"
                            class="mt-3"
                            head-variant="light"
                        >
                            <template #table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                            <template v-for="field in scoreFields" v-slot:[`head(${field.key})`]="data">
                                <div :key="'head' + field.key">
                                    <span v-if="bucket === 'week'">
                                        Week of<br />
                                        <small>{{ data.label }}</small>
                                    </span>
                                    <span v-else>
                                        {{ data.label }}
                                    </span>
                                </div>
                            </template>
                            <template #cell(category)="data">
                                {{ data.item.title }}
                            </template>
                            <template v-for="field in scoreFields" v-slot:[`cell(${field.key})`]="{ item }">
                                <div :key="field.key">
                                    <span v-if="item.scores[field.key] !== undefined">
                                        {{ item.scores[field.key] | number(1) }}
                                    </span>
                                    <span v-else>-</span>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import httpClient from '../services';
export default {
    name: 'ScoreRoleBreakdown',
    props: {
        role: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            bucket: 'week',
            defaultFields: [
                {
                    key: 'category',
                    label: 'Category'
                }
            ],
            breakdown: [],
            fields: [],
            scoreFields: []
        };
    },
    mounted() {
        if (this.role && this.role.id) {
            this.getBreakdown(this.role.id);
        }
    },
    methods: {
        getBreakdown(roleId) {
            this.isLoading = true;
            httpClient
                .get(`/scorecards/role/${roleId}?bucket=${this.bucket}`)
                .then(response => {
                    this.fields = [...this.defaultFields, ...response.data.fields];
                    this.scoreFields = response.data.fields;
                    this.breakdown = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toggleBucket(bucket) {
            this.bucket = bucket;
            this.getBreakdown(this.role.id);
        }
    },
    watch: {
        role: function(value) {
            if (value.id) {
                this.getBreakdown(value.id);
            }
        }
    }
};
</script>
