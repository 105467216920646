<template>
    <div v-if="role" class="mt-4">
        <h2>Scorecard for: {{ role.name }}</h2>
        <b-row>
            <b-col md="8" sm="12">
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-role-breakdown :role="role" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-user-breakdown :getData="getTeamBreakdown" :showAverage="false" title="User Rank" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="4" sm="12"></b-col>
        </b-row>
    </div>
</template>

<script>
import httpClient from '@/services';
import ScorecardRoleBreakdown from '../../components/ScorecardRoleBreakdown.vue';
import ScorecardUserBreakdown from '../../components/ScorecardUserBreakdown.vue';
export default {
    components: { ScorecardRoleBreakdown, ScorecardUserBreakdown },
    name: 'RoleScorecard',
    data() {
        return {
            roleId: null,
            role: null
        };
    },
    mounted() {
        const { roleId } = this.$route.params;
        this.roleId = roleId;
        this.getRole();
    },
    methods: {
        getRole() {
            httpClient
                .get(`/roles/${this.roleId}`)
                .then(response => {
                    this.role = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getTeamBreakdown(bucket) {
            return httpClient.get(`/scorecards/role/${this.roleId}/breakdownByUser?bucket=${bucket}`);
        }
    }
};
</script>
