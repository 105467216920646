<template>
    <b-card>
        <b-row>
            <b-col>
                <line-chart
                    chart-id="line-daily"
                    v-if="scores"
                    :chart-data="scores"
                    :chart-labels="labels"
                    :showTrend="false"
                />
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import LineChart from './charts/LineChart.vue';
import { DateTime } from 'luxon';
import httpClient from '@/services';

export default {
    components: { LineChart },
    name: 'ScorecardTeamChart',
    props: {
        chartData: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            scores: null,
            labels: []
        };
    },
    mounted() {
        const numWeeks = 12; // Display rolling 12 weeks
        const startWeek = DateTime.local()
            .startOf('week')
            .minus({ weeks: numWeeks });
        const labels = [];
        for (let i = 0; i <= numWeeks; i++) {
            labels.push(startWeek.plus({ weeks: i }).toISO());
        }
        this.labels = labels;
        this.getTeamBreakdown();
    },
    methods: {
        getTeamBreakdown() {
            return httpClient
                .get(`/scorecards/team/breakdownByUser?bucket=week`)
                .then(response => {
                    this.setChartData(response.data.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        setChartData(data) {
            const scores = [];
            for (const person of data) {
                const personData = [];
                for (const label of this.labels) {
                    let value = null;
                    const date = DateTime.fromISO(label)
                        .startOf('week')
                        .toISODate();
                    if (date in person.breakdown) {
                        value = person.breakdown[date];
                    }

                    personData.push(value);
                }
                scores.push({
                    label: person.firstName,
                    data: personData
                });
            }
            this.scores = scores;
        }
    }
    // watch: {
    //     chartData: function(value) {
    //         // this.scores = value.map(entry => entry.scores);
    //         // this.labels = value.map(entry => entry.day);
    //     }
    // }
};
</script>
