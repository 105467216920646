<template>
    <b-card v-if="user">
        <b-row>
            <b-col>
                <h4>Scorecards</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table responsive striped hover :items="scorecards" :busy="isLoading" :fields="fields">
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(dateCompleted)="data">
                        <router-link
                            :to="{ name: 'ViewScorecard', params: { userId: user.id, scorecardId: data.item.id } }"
                        >
                            {{ data.item.dateCompleted | luxon('date_med') }}
                        </router-link>
                    </template>
                    <template #cell(score)="data">
                        {{ data.item.score | number(1) }}
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import httpClient from '../services';
export default {
    name: 'ScorecardList',
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            scorecards: [],
            fields: [
                {
                    key: 'dateCompleted'
                },
                {
                    key: 'score',
                    sortable: false
                }
            ]
        };
    },
    mounted() {
        if (this.user && this.user.id) {
            this.getScorecards();
        }
    },
    methods: {
        getScorecards() {
            this.isLoading = true;
            httpClient
                .get(`/scorecards/user/${this.user.id}?orderBy=desc&sortBy=dateCompleted`)
                .then(response => {
                    this.scorecards = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
    watch: {
        user: function(value) {
            if (value.id) {
                this.getScorecards();
            }
        }
    }
};
</script>
