<template>
    <b-card>
        <b-row>
            <b-col>
                <h4>{{ title }}</h4>
            </b-col>
            <b-col class="text-right">
                <b-button-group size="sm">
                    <b-button :pressed="bucket === 'week'" @click="toggleBucket('week')">By Week</b-button>
                    <b-button :pressed="bucket === 'month'" @click="toggleBucket('month')">By Month</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table-simple responsive v-if="breakdown.length">
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th sticky-column>Category</b-th>
                            <b-th v-for="(heading, index) in scoreFields" :key="index">
                                <span v-if="bucket === 'week'">
                                    Week of<br />
                                    <small>{{ heading.label }}</small>
                                </span>
                                <span v-else>
                                    {{ heading.label }}
                                </span>
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-for="row in breakdown">
                            <b-tr :key="row.role._id">
                                <b-th colspan="6" class="bg-light p-1">{{ row.role.name }}</b-th>
                            </b-tr>
                            <b-tr v-for="data in row.data" :key="data._id">
                                <b-td sticky-column>{{ data.title }}</b-td>
                                <b-td v-for="(heading, hIndex) in scoreFields" :key="hIndex">
                                    <span v-if="data.scores[heading.key] !== undefined">
                                        {{ data.scores[heading.key] | number(1) }}
                                    </span>
                                    <span v-else>-</span>
                                </b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: 'ScoreBreakdown',
    props: {
        getData: {
            type: Function,
            required: true
        },
        title: {
            type: String,
            default: 'Breakdown'
        }
    },
    data() {
        return {
            isLoading: false,
            bucket: 'week',
            defaultFields: [
                {
                    key: 'category',
                    label: 'Category'
                }
            ],
            breakdown: [],
            fields: [],
            scoreFields: []
        };
    },
    mounted() {
        this.getBreakdown();
    },
    methods: {
        getBreakdown() {
            this.isLoading = true;
            this.getData(this.bucket)
                .then(response => {
                    this.fields = [...this.defaultFields, ...response.data.fields];
                    this.scoreFields = response.data.fields;
                    this.breakdown = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        toggleBucket(bucket) {
            this.bucket = bucket;
            this.getBreakdown();
        }
    }
};
</script>
