<template>
    <div v-if="scorecard">
        <b-card v-for="(item, index) in scorecard.scorecardCategories" :key="index" class="mb-5">
            <template #header>
                <h4 class="mb-0">{{ item.category.title }}</h4>
            </template>
            <b-row v-for="(question, qId) in item.questions" :key="question._id" class="mt-4 border-bottom">
                <b-col cols="9" sm="10">
                    <p>{{ question.question }}</p>
                </b-col>
                <b-col cols="3" sm="2" class="my-auto">
                    <scorecard-answer-input
                        v-model="scorecard.scorecardCategories[index].questions[qId]"
                        :question="question"
                    />
                </b-col>
            </b-row>
        </b-card>
        <b-card class="mb-5">
            <template #header>
                <h4 class="mb-0">General Feedback</h4>
            </template>
            <b-row>
                <b-col>
                    <b-form-textarea
                        id="textarea"
                        v-model="scorecard.feedback"
                        placeholder="Enter feedback..."
                        rows="3"
                        max-rows="6"
                    />
                </b-col>
            </b-row>
        </b-card>
        <b-row v-if="isOrgAdmin">
            <b-col cols="9" sm="10">
                <label for="override-completed-date">Override scorecard completed date</label>
                <b-form-datepicker
                    id="override-completed-date"
                    v-model="overrideCompleted"
                    class="mb-2"
                ></b-form-datepicker>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScorecardAnswerInput from '@/components/ScorecardAnswerInput.vue';
import { DateTime } from 'luxon';

export default {
    name: 'ScorecardForm',
    components: { ScorecardAnswerInput },
    props: {
        scorecard: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters(['isOrgAdmin']),
        overrideCompleted: {
            get: function() {
                if (this.scorecard.dateCompleted) {
                    return this.scorecard.dateCompleted;
                }
                return DateTime.local().toISO();
            },
            set: function(newValue) {
                this.scorecard.dateCompleted = DateTime.fromFormat(newValue, 'yyyy-MM-dd').toISO();
            }
        }
    },
    mounted() {
        if (!this.isOrgAdmin) {
            return;
        }
        if (this.scorecard.dateCompleted) {
            this.scorecard.dateCompleted = DateTime.fromISO(this.scorecard.dateCompleted)
                .toLocal()
                .toISO();
        }
    }
};
</script>
