<template>
    <div class="mt-4">
        <b-row>
            <b-col>
                <h1>Groups</h1>
            </b-col>
        </b-row>
        <b-card>
            <b-table responsive striped hover :items="items" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'GroupScorecard', params: { groupId: data.item.id } }">
                        {{ data.item.name }}
                    </router-link>
                </template>
                <template #cell(score)="data">
                    <span v-if="data.item.score !== undefined">
                        {{ data.item.score | number(1) }}
                    </span>
                    <span v-else>-</span>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import httpClient from '@/services';

export default {
    components: {},
    name: 'Groups',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'score',
                    sortable: true
                }
            ],
            items: [],
            name: null
        };
    },
    mounted() {
        this.getGroups();
    },
    methods: {
        getGroups() {
            httpClient
                .get('/groups?sortBy=score&orderBy=desc')
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
