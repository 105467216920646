<template>
    <div v-if="user">
        <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'UserDashboard', params: { userId: userId } }">
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Scorecard</b-breadcrumb-item>
        </b-breadcrumb>
        <b-row class="align-items-end">
            <b-col cols="11">
                <h1>Scorecard for {{ user.firstName }} {{ user.lastName }}</h1>
            </b-col>
            <b-col cols="1">
                <router-link :to="{ name: 'EditScorecard', params: { userId: userId, scorecardId: scorecardId } }">
                    Edit
                </router-link>
            </b-col>
        </b-row>
        <template v-if="scorecard">
            <b-card title="Roles" class="mb-5">
                <b-row v-for="role in scorecard.roles" :key="role._id">
                    <b-col>{{ role.name }}</b-col>
                </b-row>
            </b-card>
            <b-card v-for="(item, index) in scorecard.scorecardCategories" :key="index" class="mb-5">
                <template #header>
                    <b-row>
                        <b-col cols="9" sm="10">
                            <h4 class="mb-0">{{ item.category.title }}</h4>
                        </b-col>
                        <b-col cols="3" sm="2" class="my-auto">
                            <h4 class="mb-0">Score: {{ item.score | number(1) }}</h4>
                        </b-col>
                    </b-row>
                </template>
                <b-row v-for="question in item.questions" :key="question._id" class="mt-4 border-bottom">
                    <b-col cols="9" sm="10">
                        <p>{{ question.question }}</p>
                    </b-col>
                    <b-col cols="3" sm="2" class="my-auto">
                        {{ getQuestionAnswer(question) }}
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="mb-5">
                <template #header>
                    <h4 class="mb-0">General Feedback</h4>
                </template>
                <b-row>
                    <b-col>
                        {{ scorecard.feedback }}
                    </b-col>
                </b-row>
            </b-card>
        </template>
    </div>
</template>
<script>
import httpClient from '@/services';
export default {
    name: 'ViewScorecard',
    data() {
        return {
            userId: null,
            scorecardId: null,
            user: {},
            scorecard: null
        };
    },
    created() {
        const { userId, scorecardId } = this.$route.params;
        this.userId = userId;
        this.scorecardId = scorecardId;
        this.getUser();
        this.getScorecard();
    },
    methods: {
        getUser() {
            httpClient
                .get(`/user/${this.userId}`)
                .then(response => {
                    this.user = response.data.user;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getScorecard() {
            httpClient
                .get(`/scorecards/${this.scorecardId}`)
                .then(response => {
                    this.scorecard = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getQuestionAnswer(question) {
            if (question.questionType === 'YesNo') {
                return question.answer === 'true' ? 'Yes' : 'No';
            }
            return question.answer;
        }
    }
};
</script>
