<template>
    <div class="mt-4">
        <h2>Organization</h2>
        <b-row>
            <b-col md="8" sm="12">
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-org-breakdown />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-user-breakdown :getData="getTeamBreakdown" title="Organization Rank" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <scorecard-breakdown :getData="getBreakdown" title="Organization Role Breakdown" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="4" sm="12"></b-col>
        </b-row>
    </div>
</template>

<script>
import httpClient from '@/services';
import ScorecardUserBreakdown from '../components/ScorecardUserBreakdown.vue';
import ScorecardBreakdown from '../components/ScorecardBreakdown.vue';
import ScorecardOrgBreakdown from '../components/ScorecardOrgBreakdown.vue';
export default {
    components: { ScorecardUserBreakdown, ScorecardBreakdown, ScorecardOrgBreakdown },
    name: 'Organization',
    methods: {
        getTeamBreakdown(bucket) {
            return httpClient.get(`/scorecards/organization/breakdownByUser?bucket=${bucket}`);
        },
        getBreakdown(bucket) {
            return httpClient.get(`/scorecards/organization/breakdownByRole?bucket=${bucket}`);
        }
    }
};
</script>
