<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Roles</h1>
            </b-col>
        </b-row>
        <b-card>
            <b-table striped hover :items="items" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'RoleScorecard', params: { roleId: data.item.id } }">
                        {{ data.item.name }}
                    </router-link>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import httpClient from '@/services';

export default {
    components: {},
    name: 'Roles',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true
                }
            ],
            items: [],
            name: null
        };
    },
    mounted() {
        this.getRoles();
    },
    methods: {
        getRoles() {
            httpClient
                .get('/roles')
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
